*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  background-color: #f5f5f5;
}

.header{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: aliceblue;
  background-color: rgb(27, 204, 235);
  padding: 10px;
  box-shadow: rgb(23, 163, 163) 3px 3px 7px;
}

.theme{
  padding: 20px;
  border-radius: 40px;
  background-color: yellow;
  border: none;
}

.greet{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}

.content{
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: left;
  text-align: center;
  box-shadow: rgb(116, 154, 216) 3px 5px 15px;
  border-radius: 5px;
  margin: 3% 30%;
  padding: 10px;
}

.input-title{
  width: 100%;
  padding: 10px;
  border: none;
  font-size: 36px;
  border-radius: 5px 5px 0 0;
  outline: none;
}

.content textarea{
  text-align: left;
  width: 100%;
  height: 200px;
  padding: 10px;
  border: none;
  resize: none;
  outline: none;
}

.content button{
  padding: 3px;
  margin-left: 90%;
  background-color: aqua;
  border-radius: 1000px;
  border: none;
  box-shadow: rgb(22, 134, 134) 3px 3px 5px;
  cursor: pointer;
}

.content button:active{
  background-color: white;
  color: rgb(22, 134, 134);
}

.heading{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4%;
  margin-top: 4%;
}

.stored-notes{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 400px;
  height: 200px;
  background-color: white;
  box-shadow: rgb(116, 154, 216) 3px 5px 15px;
  border-radius: 50px;
}

.all-notes{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.note{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.stored-notes h1{
  text-align: center;
  margin-bottom: 4%;
}

.stored-notes p{
  text-align: left;
}

.delete{
  position: relative;
  top: 34px;
  left: 166px;
  padding: 4px;
  background-color: #fff;
  margin-top: 4%;
  border: none;
  cursor: pointer;
}

.login-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}

.text-login{
  font-size: 32px;
  border: none;
  outline: none;
  box-shadow: black 3px 3px 5px;
  text-align: center;
  width: 100%;
  padding: 10px;
  margin-top: 10%;
}

.login{
  text-align: center;
}

.label-login{
  padding-top: 10%;
  padding-bottom: 10%;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-button{
  padding: 15px;
  margin-bottom: 10%;
  width: 250px;
  margin-top: 10%;
  background-color: aqua;
  border: none;
  box-shadow: rgb(22, 134, 134) 3px 3px 5px;
  border-radius: 1000px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.login-button:active{
  background-color: white;
  color: rgb(22, 134, 134);
}

.login{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4.3%;
  margin-left: 35%;
  margin-right: 35%;
  padding: 10px;
  border-radius: 10px;
}

.logins{
  position: absolute;
  width: 100%;
  top: 0;
  min-height: 100vh;
  background-image: url(res/400w-LrNk7fAXxw8.webp);
  background-size: cover;
  background-position: center;
}

.logout{
  position: relative;
  padding: 10px;
  font-size: large;
  font-weight: bold;
  background-color: aqua;
  width: 100px;
  border: none;
  border-radius: 20px;
  box-shadow: rgb(22, 134, 134) 3px 3px 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.logout:hover{
  background-color: white;
  color: rgb(22, 134, 134);
}

.expanded-note-form{
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: left;
  text-align: center;
  box-shadow: rgb(116, 154, 216) 3px 5px 15px;
  border-radius: 5px;
  margin: 3% 30%;
  padding: 10px;
}

.expanded-note-form textarea{
  margin-top: 2%;
  font-size: 26px;
  text-align: left;
  width: 100%;
  height: 200px;
  border: none;
  resize: none;
  outline: none;
}

.expanded-note-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 32px;
  padding: 5% 0;
  text-align: center;
  border: none;
  outline: none;
}

.expanded-note-button{
  margin-right: 4%;
  width: 20%;
  padding: 2% 5%;
  text-align: center;
  border: none;
  outline: none;
  background-color: aqua;
  box-shadow: rgb(22, 134, 134) 3px 3px 5px;
  border-radius: 1000px;
  transition: all 0.2s ease-in-out;
}

.expanded-note-button:hover{
  background-color: white;
  color: rgb(22, 134, 134);
}

.expanded-note-button:active{
  background-color: rgb(22, 134, 134);
  color: white;
}

.expanded-note-form label{
  font-size: 32px;
  margin-bottom: 4%;
}

@media (min-width: 1px ) and (max-width: 680px){
  .content{
    margin: 3% 10%;
  }

  .header{
    width: 100%;
  }

  .stored-notes{
    width: 300px;
    height: 200px;
  }

  .all-notes{
    grid-template-columns: repeat(1, 1fr);
  }

  .login{
    margin-left: 10%;
    margin-right: 10%;
  }

  .expanded-note-form{
    margin: 3% 10%;
  }

  .input-title{
    font-size: 28px;
    text-align: center;
    width: 100%;
    padding: 10px;
  }

  .content textarea{
    font-size: 26px;
    text-align: center;
    width: 100%;
    padding: 10px;
  }

  .content button{
    width: 40px;
    padding: 5px;
    margin-left: 84%;
  }

  .heading{
    margin-top: 10%;
  }

  .expanded-note-button{
    margin-right: 4%;
    width: 30%;
    text-align: center;
    border: none;
    outline: none;
    background-color: aqua;
    box-shadow: rgb(22, 134, 134) 3px 3px 5px;
  }

}

@media (min-width: 680px) and (max-width: 1320px){

  .content{
    margin: 3% 10%;
  }

  .header{
    width: 100%;
  }

  .stored-notes{
    width: 300px;
    height: 200px;
  }

  .all-notes{
    grid-template-columns: repeat(2, 1fr);
  }

  .login{
    margin-left: 10%;
    margin-right: 10%;
  }

  .expanded-note-form{
    margin: 3% 10%;
  }
}